import clickedAudio from './../static/sounds/clicked.wav';

const logoFull = document.querySelector('#logo-full') as HTMLDivElement;
const logo = document.querySelector('#logo') as HTMLDivElement;
const black = document.querySelector('#black') as HTMLDivElement;
const black2 = document.querySelector('#black2') as HTMLDivElement;
const header = document.querySelector('header') as HTMLDivElement;
const caText = document.querySelector('#ca') as HTMLDivElement;
const journey = document.querySelector('#journey') as HTMLDivElement;

let animationPlaying = false;
let animationPrepared = false;

if (document.cookie.includes('animationPlayed=true')) {
	black.classList.add('hidden');
	black2.classList.add('hidden');
	document.body.style.overflow = 'auto';
	setTimeout(() => {
		header.classList.add('animation-done');
	}, 1000);
}

window.addEventListener('load', () => {
	prepareForAnimation();
});

setTimeout(() => {
	prepareForAnimation();
}, 5000);

function prepareForAnimation() {
	if (animationPrepared || document.cookie.includes('animationPlayed=true'))
		return;
	animationPrepared = true;
	logoFull.classList.toggle('hidden');
	logoFull.onclick = startBootAnimation;
	logoFull.style.cursor = 'pointer';
	setTimeout(() => {
		startBootAnimation();
	}, 3000);
}

async function startBootAnimation() {
	if (animationPlaying) return;
	window.scrollTo(0, 0);
	animationPlaying = true;
	logoFull.style.opacity = '1';
	await loadAudio(clickedAudio);
	logoFull.classList.toggle('centered');
	logoFull.onclick = null;
	logoFull.style.cursor = 'default';
	setTimeout(() => {
		logoFull.classList.toggle('fade-out');
		logo.classList.toggle('hidden');
		setTimeout(() => {
			logoFull.classList.add('hidden');
			setTimeout(() => {
				black.classList.add('hidden');
				journey.classList.toggle('in-animation');
				setTimeout(() => {
					black2.classList.add('hidden');
					logo.classList.add('hidden');
					document.body.style.overflow = 'auto';
					document.cookie = 'animationPlayed=true';
					setTimeout(() => {
						header.classList.toggle('animation-done');
						journey.classList.toggle('in-animation');
					}, 1000);
				}, 4500);
			}, 1100);
		}, 900);
	}, 1000);
}

function loadAudio(audioPath: string): Promise<HTMLAudioElement> {
	return new Promise((resolve) => {
		const audio = new Audio(audioPath);
		audio.oncanplaythrough = () => {
			audio.play();
			resolve(audio);
		};
		audio.load();
	});
}
//copy ca to clipboard and make the cursor a pointer
caText.addEventListener('click', () => {
	navigator.clipboard.writeText('2bZzs6UZGWqr1rUzfe6GcUoA4MmrzQrYJsYRLWttNJhS');
	caText.innerText = 'CA: Copied!';
	setTimeout(() => {
		caText.innerText = 'CA: 2bZzs6UZGWqr1rUzfe6GcUoA4MmrzQrYJsYRLWttNJhS';
	}, 1000);
});
